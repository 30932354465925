<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="搜索关键字" label-width="100px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称/编码" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="姓名" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.t_name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="编码" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.t_code }}
				  </template>
				</el-table-column>
				<el-table-column label="年级" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.nianji }}
				  </template>
				</el-table-column>
				<el-table-column label="学科" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.xueke }}
				  </template>
				</el-table-column>
				<el-table-column label="获奖名称" min-width="150" align="center">
				  <template slot-scope="scope">
					{{ scope.row.lj_name }}
				  </template>
				</el-table-column>
				<el-table-column label="分数" min-width="160" align="center">
				  <template slot-scope="scope">
					{{ scope.row.score }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="140">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
<!-- 				<el-table-column label="操作" min-width="100" align="center" v-if="isFinish">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column> -->
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.size"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="650px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small">
		    <el-form-item label="年级" prop="nianji">
		      <el-select v-model="form.nianji" placeholder="请选择年级" style="width: 100%;">
		        <el-option :value="item.id" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
		      </el-select>
		    </el-form-item>
			<el-form-item label="学科" prop="xueke">
			  <el-input v-model="form.xueke" placeholder="请输入学科" type="text" clearable />
			</el-form-item>
		    <el-form-item label="老师编码" prop="t_code">
		      <el-input v-model="form.t_code" placeholder="请输入老师编码" type="text" clearable />
		    </el-form-item>
			<el-form-item label="老师姓名" prop="t_name">
			  <el-input v-model="form.t_name" placeholder="请输入老师姓名" type="text" clearable />
			</el-form-item>
			<el-form-item label="获奖名称" prop="lj_name">
			  <el-input v-model="form.lj_name" placeholder="请输入获奖名称" type="text" clearable />
			</el-form-item>
			<el-form-item label="分数" prop="score">
			  <el-input v-model="form.score" placeholder="请输入分数" type="number" clearable />
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'jsry_list',
				id:'',
				s_id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					p_id:'',
					s_id:'',
					nianji:'',
					xueke:'',
					t_code:'',
					t_name:'',
					lj_name:'',
					score:0,
				},
				rules:{
					nianji: [
					  { required: true, message: '请输入年级号', trigger: 'blur' }
					],
					xueke: [
					  { required: true, message: '请输入学科', trigger: 'blur' }
					],
					t_code: [
					  { required: true, message: '请输入老师编码', trigger: 'blur' }
					],
					t_name: [
					  { required: true, message: '请输入老师名称', trigger: 'blur' }
					],
					lj_name: [
					  { required: true, message: '请输入获奖名称', trigger: 'blur' },
					],
					score: [
					  { required: true, message: '请输入分数', trigger: 'blur' },
					],
				},
				nianji:[
					{id:1,name:'高一'},
					{id:2,name:'高二'},
					{id:3,name:'高三'},
				],
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
		},
		computed:{
			isFinish(){
				if(this.list.length > 0 && this.list[0].is_finish == 2){
					return true
				}else{
					return false
				}
			}
		},
		methods:{
			reset() {
			  this.form = {
			   p_id:this.id,
			   s_id:this.s_id,
			   nianji:'',
			   xueke:'',
			   t_code:'',
			   t_name:'',
			   lj_name:'',
			   score:0,
			  }
			  this.resetForm('form')
			},
		}
	}
</script>

<style>
</style>